import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const Modal = (props) => {
  return (
    <Transition appear show={props?.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props?.onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-900 bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div
            className={`flex min-h-full items-center justify-center ${
              props?.contPadding ? props.contPadding : " p-4 "
            } text-center`}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel
                className={`w-full transform overflow-hidden ${
                  props?.radius ? props.radius : " rounded-xl "
                } bg-white p-6 
              text-left align-middle shadow-xl transition-all ${
                props.xl
                  ? " max-w-xl "
                  : props.width
                  ? props.width
                  : " max-w-md "
              } 
              ${props.height && props.height}
              `}>
                <div className={`flex w-full h-full`}>
                  {props?.icon && (
                    <div
                      className={`mr-5 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full 
                      sm:h-10 sm:w-10 ${
                        props.warning && " bg-red-100  text-alert "
                      } ${props.info && " bg-yellow-100 text-fore "}`}>
                      {props.icon}
                    </div>
                  )}
                  <div className="w-full">
                    <div className="flex items-center">
                      {props?.title && (
                        <Dialog.Title
                          as="h3"
                          className={`text-lg ${
                            props?.titleCenter && " text-center "
                          } font-medium leading-6 text-gray-900 w-full`}>
                          {props?.title}
                        </Dialog.Title>
                      )}
                    </div>
                    {props?.content}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
